.page-account {
  & div.background-container{
   width: 100%;
   height: 40vh;

  & ion-img.background-cover{
    object-fit: cover !important;
    height: 35vh;
}
  }
  // Cards en général
  & ion-card{
    margin: auto;
    height: 150px;
    width: 90%;
    border: 1px solid var(--ion-color-medium);
}
@keyframes animate-premium {
	0% {
		background-position: -500%;
	}
	100% {
		background-position: 500%;
	}
}
// Card Premium
  & ion-card.premium-content{
      & ion-card-title{
	color: var(--primary);
	overflow: hidden;
	background-color: var(--ion-color-primary);
	background-image: linear-gradient(43deg, #ad7b10 0%, #f4f4f4 46%, #ad7b10 100%);
	background-repeat: no-repeat;
	background-size: 80%;
	animation: animate-premium 5s linear infinite;
	-webkit-text-fill-color: rgba(255,255,255,.1);
	-webkit-background-clip: text;
	background-clip: text;}
    & ion-card-content{color: var(--ion-color-primary)}}

// Card déconnexion
  & .logout-card{
    width: 50%;
    margin: auto;
  & ion-card{
    height: 150px;
    width: 100%;
    & ion-card-content.margin-auto{margin : auto;}
    & .next-to-each-other{
      display: flex;
      align-items:center;
      justify-content: center;
      & ion-icon{
        padding-right: 5px;
        height: 20px !important;
      }
    }
  }
  }


  // & .block-avatar {
  //   margin-top: -90px;
  //   & .avatar-default {
  //     display: flex;
  //     background: var(--ion-color-medium);
  //     border: 1px solid #fff;
  //     border-radius: 100%;
  //     width: 120px;
  //     height: 120px;
  //     & ion-icon {
  //       margin: auto;
  //       color: #fff;
  //       font-size: 50px;
  //     }
  //   }
  //   & ion-avatar {
  //     background: #fff;
  //     border: 1px solid #fff;
  //     width: 120px;
  //     height: 120px;
  //   }
  //   & .avatar {
  //     position: relative;
  //     & .add-avatar {
  //       display: flex;
  //       position: absolute;
  //       bottom: 0;
  //       right: 0;
  //       background: var(--ion-color-primary);
  //       border: 2px solid #fefefe;
  //       border-radius: 100%;
  //       color: #fefefe;
  //       cursor: pointer;
  //       width: 36px;
  //       height: 36px;
  //       & div { margin: auto; }
  //       & ion-icon { font-size: 22px; }
  //     }
  //   }
  // }

  & .user-name { margin-top: 10px; }
  & .account-links {
    margin-top: 25px;
    padding: 0;
    // border-top: 1px solid var(--ion-color-light);
    & ion-item {
      --padding-start: 0;
      --inner-padding-end: 0;
      --min-height: 53px;
    }
    & ion-row { width: 100%; }
    & ion-icon { margin-top: 2px; }
  }
  & .block-notifications {
    & hr { margin: 20px 10px 10px; }
  }
}


// InfosView.tsx

.footer-buttons{
  margin: auto !important;
  .width-250px{
    width: 250px;
  }
}

ion-content.page-footer{
  & .form{
    width: 50%;
    margin: auto;
    & ion-label{color : var(--ion-color-primary); font-size: 16px !important;}
    & ion-input{color: var(--ion-color-dark); font-size: 14px !important;}
  }
}