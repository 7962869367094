.page-videos {
  & .block-video {
    & ion-icon { margin-top: 2px; }
    div.video-width {
      width: 70%;
      margin: auto;
    }
  }
  & .block-chips { margin-top: 5px; }
  & .total-progress {
    & ion-list-header {
      padding: 0;
      min-height: initial;
    }
    & ion-progress-bar { margin-top: 10px; }
  }
  & .video-resume {
    & h5 { margin-left: 5px; }
    & ion-card { margin: 5px; }
    & ion-card-header,
    & ion-card-content {
      padding: 10px;
    }
    & ion-card-header {
      & .filter{
          vertical-align: top;
          margin-left : 50px;
          height: 18px;
          display: inline-block;
        }
       padding-bottom: 5px; }
    & ion-card-content {
      padding-top: 0;
      padding-bottom: 0;
    }
    & video { border-radius: 4px; }
    & .items-overflow {
      margin: -5px 0 0 -10px;
      & ion-card {
        margin: 0 5px;
        width: 204px;
        & ion-img {
          display: inline-block;
        }
        & ion-card-content {
          padding: 0 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          & .video-time { margin-top: -10px; }
          & ion-icon { margin-top: 5px; }
        }
      }
    }
  }
  & .list-videos {
    padding-top: 0;
    border-top: 1px solid var(--ion-color-light);
    & details {
      width: 100%;
      &[open] {
        & .video-img {
          width: 28px;
          height: 25px;
        }
        & .video-title { font-size: 18px; }
        & .total-videos { display: none; }
        & .icon-arrow {
          margin-top: 5px;
          transform: rotate(180deg);
        }
      }
    }
    & .video-img {
      object-fit: cover;
      width: 57px;
      height: 50px;
    }
    & .video-details {
      & ion-col {
        padding-top: 0;
        padding-bottom: 0;
      }
      & ion-progress-bar { margin-top: 5px; }
    }
    & .total-videos {
      display: block;
      margin-left: 5px;
    }
    & .icon-arrow { font-size: 22px; }
    & .items-overflow {
      margin: -5px 0 0 -10px;
      animation: showVideos .5s ease-in-out;
      min-height: 170px;
      & ion-card {
        margin: 0 5px;
        width: 204px;
        min-height: 45px;
        & ion-img {
          display: block;
          min-height: 113px;
        }
        & ion-card-content {
          margin-bottom: -50px;
          padding: 0 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          & .video-time { margin-top: -10px; }
          & ion-icon { margin-top: 5px; }
        }
      }
    }
  }
}

@keyframes showVideos {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
