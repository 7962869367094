.page-home {
  & ion-card, .card-ios, .card-md {
        border: 0 !important;
        box-shadow: 0 !important;
        border: none !important;
        border-style: none !important;
        position: relative;
        text-align: center;
        // border-top: 3px solid colorofyourchosing
      }
      .card{
        border: 0 !important;
        box-shadow: none !important;
        border: none !important;
        border-style: none !important;
      }    
  & .slider {
    padding: 15px 30px 0 0;
    background-image: url("/assets/tmp/slider.jpg");
    background-position: center center;
    background-size: cover;
    font-size: 14px;
    width: 100%;
    height: 121px;
    & hr {
      margin: 12px 0;
    }
    & ion-router-link ion-icon {
      font-size: 16px;
      vertical-align: bottom;
    }
    & .logo {
      display: inline-block;
      margin: 0px 10px -10px 0;
      width: 30px;
    }
    & .title-formaceo {
      display: inline-block;
      margin: 10px 0 20px;
      font-size: 20px;
    }
    & .waving-hand {
      width: 18px;
    }
    & .text-welcome {
      color: #fff;
      font-size: 16px;
    }
  }
  & h3 {
    margin: 0;
    margin-left: 5px;
  }
  & .title-details {
    margin-top: -10px;
    & ion-icon {
      margin-top: 5px;
    }
  }
  & ion-badge {
    margin-top: 3px;
  }
  & .items-overflow {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 50px;
    grid-auto-rows: minmax(50px, auto);
    margin-left: 25px;
    & ion-card {
      margin: 0 5px;
      width: 250px;
      & ion-img {
        display: block;
        min-height: 115px;
        min-width: 200px;
      }
    }
  }
  & ion-card-header,
  & ion-card-content {
    padding: 5px 10px !important;
  }
  & ion-card-content {
    margin-bottom: -5px;
    padding-bottom: 0 !important;
    & ion-col {
      padding: 0;
      padding-left: 5px;
      &:first-child {
        padding-left: 0;
      }
    }
    & ion-avatar {
      &,
      & img {
        width: 20px;
        height: 20px;
      }
    }
  }
  & .block-sos ion-card-subtitle:last-child {
    color: #f63a51;
  }
  & .see-all-button {
    width: 120px;
    color: lightgrey;
  }
  & span.text-custom {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 8px;
    margin-left: 5px;
  }
  ion-icon.camera-icon {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
  }
}
