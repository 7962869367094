.page-premium {
  & .premium-intro {
    font-size: 14px;
    & ion-icon { font-size: 20px; }
  }
  
  & .premium-title ion-badge { margin-top: 2px; }
  & h5 { margin: 0; }
  & ion-card {
    margin-top: 0;
    border: none !important;
    & > ion-card-content { padding: 5px; }
    & ion-card {
      margin: 0;
      & ion-col {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    & ion-icon { margin-top: 5px; }
    & + ion-card { margin-top: 10px; }
    & ion-button {
      & ion-icon {
        margin: 0 3px;
        font-size: 14px;
      }
    }
  }
  & ion-card.height-100 {height: 100%;}
  & .books {
    width: 80%;
    margin: auto;
    	transition: all 0.2s ease-in-out;

  }
  & ion-img.books:hover{
	transform: scale(1.03);
  }
  & ul {
    margin: 0;
    padding: 0;
    list-style-type: initial;
  }
}
